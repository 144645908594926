export class HTMLAjaxContentElement extends HTMLElement {
    private static readonly DOM_PARSER = new DOMParser();
    private static readonly ALLOWED_MIME_TYPES = [
        'application/xhtml+xml',
        'application/xml',
        'image/svg+xml',
        'text/html',
        'text/xml',
    ];

    constructor() {
        super();
        this.hidden = true;

        if (!this.url) {
            console.warn('<ajax-content> requires a url-attribute');
        }

        this.fetchContent()
            .catch((err) => console.error('Cannot load ajax content. Got error: ', err))
            .finally(() => (this.hidden = false));

        this.addEventListener('submit', (event) => {
            if (event.target instanceof HTMLFormElement) {
                event.preventDefault();
                const formData = new FormData(event.target);

                Array.from(event.target.querySelectorAll<HTMLButtonElement>('button[name]')).forEach((formButton) => {
                    formData.set(formButton.name, formButton.value);
                });

                this.fetchContent(event.target.action, {
                    method: event.target.method || 'POST',
                    body: formData,
                });
            }
        });
    }

    get url(): string {
        return this.getAttribute('url') as string;
    }

    get subject(): string | null {
        return this.getAttribute('subject');
    }

    private async fetchContent(url = this.url, requestInit?: RequestInit) {
        const response = await fetch(url, requestInit);
        const responseType = HTMLAjaxContentElement.getMimeTypeFromResponse(response);
        if (!response.ok) {
            throw response;
        }
        if (!responseType || !HTMLAjaxContentElement.ALLOWED_MIME_TYPES.includes(responseType)) {
            throw new Error(
                `Unsupported response mime type ${responseType}. Allowed types are ${HTMLAjaxContentElement.ALLOWED_MIME_TYPES.join(
                    ', '
                )}`
            );
        }
        const plainDocument = await response.text();
        const parsedDocument = HTMLAjaxContentElement.DOM_PARSER.parseFromString(plainDocument, 'text/html');
        const content = parsedDocument.getElementById('content');
        //        console.log('content ', content.firstChild);
        if (content) {
            // console.log('content find ', content.querySelectorAll('script'));
            // console.log('script elements: ', scriptElements);
            const scriptElements = content.querySelectorAll('script');

            this.clearContent();
            this.setSubjectIfPresent(content);
            while (content.firstChild) {
                this.append(content.firstChild);
            }

            // todo: dont add script elements in above case / remove from payload
            // if found, insert any found script with proper <script> tag in order to be parsed.
            for (var i = 0; i < scriptElements.length; i++) {
                var newScript = document.createElement('script');
                if (scriptElements[i].firstChild) {
                    var inlineScript = document.createTextNode(scriptElements[i].firstChild!.textContent || '');
                    newScript.appendChild(inlineScript);
                    this.appendChild(newScript);
                }
            }
        }
    }

    private setSubjectIfPresent(content: Element) {
        if (this.subject) {
            const subjectInput = Array.from(content.querySelectorAll<HTMLInputElement>('input[type="text"]')).find(
                (input) => input.id.endsWith('subject')
            );
            if (subjectInput) {
                subjectInput.value = this.subject;
            }
        }
    }

    private clearContent() {
        while (this.lastChild) {
            this.lastChild.remove();
        }
    }

    private static getMimeTypeFromResponse(response: Response): string {
        const contentType = response.headers.get('content-type') || '';
        const [mimeType] = contentType.split(';').map((part) => part.trim().toLowerCase());

        return mimeType;
    }
}

customElements.define('ajax-content', HTMLAjaxContentElement);
