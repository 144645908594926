import { createFocusTrap, FocusTargetValueOrFalse, FocusTrap } from 'focus-trap';
import { TransitionHelper } from '../../helper/TransitionHelper';

export interface NavOptions {
    navElementId: string;
}

export abstract class AbstractNav extends EventTarget {
    public static readonly IS_DESKTOP_NAV_MQL = window.matchMedia('(min-width: 75rem)'); // Breakpoint XL -> 1200px
    protected static readonly PAGE_HEADER = document.getElementById('page-header')!;

    protected readonly navElement: HTMLElement;
    protected readonly navTransitionHelper: TransitionHelper;
    protected readonly toggleElements: HTMLElement[];

    private readonly focusTrap: FocusTrap;

    constructor(options: NavOptions, private readonly TRANSITION_NAME = 'slide-right') {
        super();
        const navElement = document.getElementById(options.navElementId) as HTMLElement;
        this.navElement = navElement;
        this.navTransitionHelper = new TransitionHelper(navElement, this.TRANSITION_NAME);

        this.focusTrap = createFocusTrap(this.trapElements, {
            allowOutsideClick: true,
            escapeDeactivates: false,
            initialFocus: () => this.initialFocusTarget(),
        });

        this.toggleElements = Array.from(document.querySelectorAll(`[aria-controls=${navElement.id}]`));
        this.toggleElements.forEach((button) => {
            button.addEventListener('click', (event) => this.toggleNavigation(event));
        });

        AbstractNav.IS_DESKTOP_NAV_MQL.addEventListener('change', () => {
            this.focusTrap.updateContainerElements(this.trapElements);
        });

        this.element.addEventListener('keydown', (event) => this.hideOnEscape(event));
    }

    get element(): HTMLElement {
        return this.navElement;
    }

    get trap(): FocusTrap {
        return this.focusTrap;
    }

    protected get trapElements(): HTMLElement[] {
        const output = [this.navElement];
        if (!AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            output.push(AbstractNav.PAGE_HEADER);
        }
        return output;
    }

    protected get isOpen(): boolean {
        return !this.navElement.hidden;
    }

    async toggleNavigation(event?: Event) {
        event?.preventDefault();

        if (this.isOpen) {
            await this.hide();
        } else {
            await this.show();
        }
    }

    async show(event?: Event) {
        event?.preventDefault();

        this.dispatchEvent(new CustomEvent('beforeOpen'));
        if (!this.isOpen) {
            await this.navTransitionHelper.show();
            this.focusTrap.activate();
            this.setToggleElementExpandedState();
        }
        this.dispatchEvent(new CustomEvent('afterOpen'));
    }

    async hide(event?: Event, returnFocus?: boolean) {
        event?.preventDefault();

        this.dispatchEvent(new CustomEvent('beforeHide'));
        if (this.isOpen) {
            this.focusTrap.deactivate({ returnFocus });
            await this.navTransitionHelper.hide();
            this.setToggleElementExpandedState();
        }
        this.dispatchEvent(new CustomEvent('afterHide'));
    }

    protected setToggleElementExpandedState() {
        this.toggleElements.forEach((element) => {
            element.setAttribute('aria-expanded', (!this.navElement.hidden).toString());
        });
    }

    protected initialFocusTarget(): FocusTargetValueOrFalse {
        // return undefined is actually a valid return value and will force focusTrap to use default behaviour
        // however, focusTrap typings suck, so here we go 🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡
        return undefined as unknown as FocusTargetValueOrFalse;
    }

    protected hideOnEscape(event: KeyboardEvent) {
        if (event.key === 'Escape' && this.focusTrap.active && !this.focusTrap.paused) {
            event.stopPropagation();
            this.hide(event);
        }
    }
}
